import { Outlet } from 'react-router-dom'

const Moorpay = () => {
  return (
    <>
      <Outlet />
    </>
  )
}

export default Moorpay
