export const properties = {
  header: 'MOORMANAGE/Boatyards',
  customerPageHeader: 'MOORMANAGE/Customers',
  boatyardMooringHeader: 'Boatyard Moorings',
  serviceAreaMooringHeader: 'Service Area Moorings',
  boatyardDetail: 'Boatyards Detail',
  serviceAreaDetail: 'Service Area Detail',
  address: 'Address',
  mooringInventoried: 'Mooring Inventoried',
  boatyardGPSCoordinates: 'Boatyard GPS Coordinates',
  serviceAreaGPSCoordinates: 'Service Area GPS Coordinates',
  moormanagePermission: 'MOORMANAGE/Permission',
  CustomersOwner: 'Companies',
  CustomerOwnerUsers: 'User Management',
  CustomerDetails: 'Customer Details',
  CustomerList: 'Customer List',
  label: 'ADD NEW',
  customerMooringHeader: 'Customer Moorings',
  reportHeader: 'Report',
  MoormanageMoorings: 'MOORMANAGE/Moorings',
  Customersadmins: 'Customersadmins',
  workOrderHeader: 'Work Orders',
  customerHeader: 'Customers',
  customerRecord: 'Customers Record',
  mooringHeader: 'Moorings',
  Users: 'User Management',
  Settings: 'Customer Mapping',
  Technician: 'Technician',
  imageHeader: 'Images',
  mooringInformation: 'Mooring Information',
  customerInformation: 'Customer Information',
  imageInformation: 'Image Information',
  noDataMessage: 'No data available',
  Address: 'Address',
  WorkOrdersPendingApprovalHeaders: 'Work Orders Pending Approval',
  OutstandingInvoicesHeader: 'Outstanding Invoices',
  exportToPdfText: 'Export To PDF',
  buttonText: 'ADD NEW',
  voiceMemoHeader: 'Voice Memo',
}
